import React from "react";
import {
  Box,
  Typography,
  InputLabel,
  Button,
  TextField,
  Grid,
  Checkbox,
  IconButton,
  Dialog, DialogTitle, DialogContent, DialogContentText
} from "@mui/material";
import { Link } from 'react-router-dom';
import { Visibility, VisibilityOff } from "@mui/icons-material";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CloseIcon from '@mui/icons-material/Close';
import SignupController, { Props } from "./SignupController.web";
import { loginLogo, loginImage } from "./assets";

export default class Signup extends SignupController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const { handleInputChange, togglePasswordVisibility, handleSubmit, setPrivacyPolicyOpen, setTermsOpen } = this;
    const { name, dms, phone, email, password, confirmPassword, consent, showPassword, showConfirmPassword, privacyPolicyOpen, termsOpen, errors } = this.state;

    return (
      <>
        <div className="Toastify"></div>
        <Grid container direction={'row'}>
          <Grid item xs={6} sx={{
            height: "599px",
            overflowY: "scroll"
          }}>
            <img src={loginLogo.default} style={{ height: "10%", width: "25%", padding: "15px" }} alt="Login Logo" />
            <form onSubmit={handleSubmit} data-testid="signup-form">
              <Box sx={webStyle.formWrapper}>
                <Typography variant="h6" align="center" sx={{
                  fontFamily: 'Montserrat, sans-serif',
                  fontWeight: 700, fontSize: "22px", color: "#001C55"
                }}>
                  Start Scheduling Now!
                </Typography>

                <Box sx={webStyle.inputWrapper}>
                  <InputLabel style={webStyle.label}>Name</InputLabel>
                  <TextField
                    name="name"
                    value={name}
                    onChange={(e) => handleInputChange("name", e.target.value)}
                    fullWidth
                    variant="outlined"
                    placeholder="Enter Name"
                    sx={webStyle.textFieldStyle}
                    InputProps={{
                      style: webStyle.inputStyle
                    }}
                    error={!!errors.name}
                    helperText={errors.name}
                  />{errors.name}
                </Box>

                <Box sx={webStyle.inputWrapper}>
                  <InputLabel style={webStyle.label}>DMS Number</InputLabel>
                  <TextField
                    name="dms"
                    value={dms}
                    onChange={(e) => handleInputChange("dms", e.target.value)}
                    fullWidth
                    variant="outlined"
                    placeholder="Enter DMS Number"
                    sx={webStyle.textFieldStyle}
                    InputProps={{
                      style: webStyle.inputStyle
                    }}
                    error={!!errors.dms}
                    helperText={errors.dms}
                  />
                </Box>

                <Box sx={webStyle.inputWrapper}>
                  <InputLabel style={webStyle.label}>Phone Number</InputLabel>
                  <TextField
                    name="phone"
                    value={phone}
                    onChange={(e) => handleInputChange("phone", e.target.value)}
                    fullWidth
                    variant="outlined"
                    type="tel"
                    placeholder="(123) 456-7890"
                    sx={webStyle.textFieldStyle}
                    InputProps={{
                      style: webStyle.inputStyle
                    }}
                    error={!!errors.phone}
                    helperText={errors.phone}
                  />
                </Box>

                <Box sx={webStyle.inputWrapper}>
                  <InputLabel style={webStyle.label}>Email</InputLabel>
                  <TextField
                    name="email"
                    value={email}
                    onChange={(e) => handleInputChange("email", e.target.value)}
                    fullWidth
                    variant="outlined"
                    type="email"
                    placeholder="Enter Email"
                    sx={webStyle.textFieldStyle}
                    InputProps={{
                      style: webStyle.inputStyle
                    }}
                    error={!!errors.email}
                    helperText={errors.email}
                  />
                </Box>

                <Box sx={webStyle.inputWrapper}>
                  <InputLabel style={webStyle.label}>Password</InputLabel>
                  <TextField
                    name="password"
                    type={showPassword ? "text" : "password"}
                    value={password}
                    onChange={(e) => handleInputChange("password", e.target.value)}
                    fullWidth
                    variant="outlined"
                    placeholder="Enter Password"
                    sx={webStyle.textFieldStyle}
                    InputProps={{
                      style: webStyle.inputStyle,
                      endAdornment: (
                        <IconButton
                          onClick={() => togglePasswordVisibility('showPassword')}
                          edge="end"
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      ),
                    }}
                    error={!!errors.password}
                    helperText={errors.password}
                  />
                   <Box sx={webStyle.passwordRequirements}>
                    {this.passwordRequirements.map((req, index) => (
                      <Box key={index} sx={webStyle.requirementItem}>
                        <CheckCircleIcon
                          sx={this.getPasswordRequirementIconStyle(req, password)}
                        />
                        <Typography sx={webStyle.requirementText}>
                          {req.text}
                        </Typography>
                      </Box>
                    ))}
                  </Box>
                </Box>

                <Box sx={webStyle.inputWrapper}>
                  <InputLabel style={webStyle.label}>Confirm Password</InputLabel>
                  <TextField
                    name="confirmPassword"
                    type={showConfirmPassword ? "text" : "password"}
                    value={confirmPassword}
                    onChange={(e) => handleInputChange("confirmPassword", e.target.value)}
                    fullWidth
                    variant="outlined"
                    placeholder="Confirm your password"
                    sx={webStyle.textFieldStyle}
                    InputProps={{
                      style: webStyle.inputStyle,
                      endAdornment: (
                        <IconButton
                          onClick={() => togglePasswordVisibility('showConfirmPassword')}
                          edge="end"
                        >
                          {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      ),
                    }}
                    error={!!errors.confirmPassword}
                    helperText={errors.confirmPassword}
                  />
                </Box>

                <Box sx={webStyle.inputWrapper}>
                  <Box sx={webStyle.consentContainer}>
                    <Checkbox
                      name="consent"
                      checked={consent}
                      onChange={(e) => handleInputChange("consent", e.target.checked)}
                      sx={webStyle.checkbox}
                    />
                    <Typography sx={webStyle.consentText}>
                      I hereby consent to the
                      <Button
                        onClick={() => setPrivacyPolicyOpen(true)}
                        sx={webStyle.consentButton}
                      >
                        Privacy Policy
                      </Button>
                      and
                      <Button
                        onClick={() => setTermsOpen(true)}
                        sx={webStyle.consentButton}
                      >
                        Terms and Conditions
                      </Button>
                    </Typography>
                  </Box>
                  {errors.consent && (
                    <Typography color="error" variant="caption">
                      {errors.consent}
                    </Typography>
                  )}
                </Box>

                <Button
                  type="submit"
                  sx={webStyle.buttonStyle}
                  variant="contained"
                >
                  Create account
                </Button>

                <Typography variant="body2" align="center"
                  sx={{
                    fontWeight: 400, fontSize: "16px"
                  }}
                >
                  Already have an account?
                  <Link to="/login" style={{
                    fontFamily: 'Montserrat, sans-serif', display: "inline",
                    fontWeight: 700, fontSize: "16px", color: "#0074BF", textDecoration: "none",
                    marginLeft: "7px"
                  }}>
                    Login
                  </Link >
                </Typography>
              </Box>
            </form>
          </Grid>
          <Grid item xs={6} padding={0}>
            <img src={loginImage.default} style={{ height: "100vh", width: "100%" }} alt="Login" />
          </Grid>
        </Grid>
        <PrivacyPolicyModal
          open={privacyPolicyOpen}
          onClose={() => setPrivacyPolicyOpen(false)}
        />
        <TermsAndConditionsModal
          open={termsOpen}
          onClose={() => setTermsOpen(false)}
        />
      </>
    );
  }
}

const webStyle = {
  inputWrapper: {
    position: "relative",
    width: "100%",
    maxWidth: "360px",
    mb: 1
  },
  formWrapper: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "center"
  },
  label: {
    fontSize: "16px",
    fontWeight: 600,
    marginTop: "2px",
    color: "#334155"
  },
  textFieldStyle: {
    "&.Mui-focused": {
      "& .MuiOutlinedInput-notchedOutline": {
        border: "1px solid #CBD5E1",
        borderWidth: "1px",
      },
    },
    backgroundColor: "#ffffff",
    color: "#94A3B8",
    mb: "3px",
  },
  inputStyle: {
    border: "1px solid #CBD5E1",
    borderRadius: '20px',
    fontWeight: 400,
    width: "100%"
  },
  buttonStyle: {
    height: "50px",
    width: "100%",
    maxWidth: "360px",
    marginTop: "25px",
    marginBottom: "20px",
    border: "none",
    borderRadius: "20px",
    backgroundColor: "#0074BF",
    color: "white",
    cursor: "pointer",
    fontWeight: 700,
    textTransform: "none"
  },
  passwordRequirements: {
    marginTop: '10px',
  },
  requirementItem: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '5px',
  },
  checkIcon: {
    color: '#34D399',
    fontSize: '16px',
    marginRight: '8px',
  },
  uncheckIcon: {
    color: '#9E9E9E',
    fontSize: '16px',
    marginRight: '8px',
  },
  requirementText: {
    fontSize: '14px',
    color: '#333',
  },
  consentContainer: {
    display: 'flex',
    alignItems: 'flex-start',
    backgroundColor: '#F8FAFC',
    borderRadius: '4px',
  },
  checkbox: {
    color: '#3B82F6',
    '&.Mui-checked': {
      color: '#3B82F6',
    },
    padding: '0',
    marginRight: '8px',
  },
  consentText: {
    fontSize: '14px',
    color: '#1E293B',
    display: 'inline',
    lineHeight: 1.5,
  },
  consentButton: {
    color: '#0F172A',
    textTransform: 'none',
    padding: '0 2px',
    minWidth: 'auto',
    textDecoration: 'underline',
    '&:hover': {
      backgroundColor: 'transparent',
      textDecoration: 'underline',
    },
  },
};

const PrivacyPolicyModal = ({ open, onClose }: any) => {
  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth >
      <DialogTitle >
        Privacy Policy
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{ position: 'absolute', right: 8, top: 8 }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          <Typography paragraph>
            Our Privacy Policy outlines how we collect, use, and protect your personal information when you use our website, products, and services.
          </Typography>
          <Typography paragraph>
            We are committed to safeguarding your privacy and ensuring that your personal data is protected. This policy explains the types of information we collect, how we use it, and the measures we take to keep it secure.
          </Typography>
          <Typography paragraph>
            By using our services, you agree to the collection and use of information in accordance with this policy. We will not use or share your information with anyone except as described in this Privacy Policy.
          </Typography>
          {/* Add more content as needed */}
        </DialogContentText>
      </DialogContent>
    </Dialog>
  );
};

const TermsAndConditionsModal = ({ open, onClose }: any) => {

  const textStyles = {
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: '24px',
    textAlign: 'justify',
    color: '#0F172A'
  };


  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth
      PaperProps={{
        style: { borderRadius: 16 }
      }}>
      <DialogTitle sx={{
        borderBottom: "1px solid #E2E8F0",
        fontSize: '22px',
        fontWeight: 700,
        lineHeight: '32px',
        textAlign: 'left',
        color: "#001C55"
      }}>
        Terms and Conditions
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{ position: 'absolute', right: 8, top: 8 }}
        >
          <CloseIcon sx={{ width: "18px", height: "18px", color: "#0074BF" }} />
        </IconButton>
      </DialogTitle>
      <DialogContent sx={{ marginTop: 4 }}>
        <DialogContentText>
          <Typography paragraph sx={textStyles}>
            Terms and conditions outline what users can and cannot do with your website, products, and services. They lay out the rules to protect you in case of misuse and enable you to take action if it becomes necessary.
          </Typography>
          <Typography paragraph sx={textStyles}>
            It's also referred to by other names such as terms of service (ToS) and terms of use (ToU). Even though they have different names, in fact – there is no difference.
          </Typography>
          <Typography paragraph sx={textStyles}>
            In order to use your website, products, or services, your customers usually must agree to abide by your terms and conditions first.
          </Typography>
          <Typography paragraph sx={textStyles}>
            By agreeing to the terms and conditions, users acknowledge their responsibilities and understand the guidelines set forth. This agreement helps to ensure a smooth, transparent relationship between the user and the provider, minimizing potential disputes or misunderstandings. Failure to adhere to these terms could result in restricted access or termination of services. Therefore, it's crucial to read and fully understand the conditions before proceeding.
          </Typography>
        </DialogContentText>
      </DialogContent>
    </Dialog>
  );
};