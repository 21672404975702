import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
}

export interface S {
  name: string;
  dms: string;
  phone: string;
  email: string;
  password: string;
  confirmPassword: string;
  consent: boolean;
  showPassword: boolean;
  showConfirmPassword: boolean;
  privacyPolicyOpen: boolean;
  termsOpen: boolean;
  errors: {
    [key: string]: string;
  };
  passwordStrength: number;
}

export interface SS {
  id: any;
}

export interface PasswordRequirement {
    text: string;
    regex?: RegExp;
    test?: (value: string) => boolean;
  }

export default class SignupController extends BlockComponent<Props, S, SS> {
  validationApiCallId: string = "";
  createAccountApiCallId: string = "";
  passwordRequirements: PasswordRequirement[];

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage)
    ];

    this.state = {
      name: "",
      dms: "",
      phone: "",
      email: "",
      password: "",
      confirmPassword: "",
      consent: false,
      showPassword: false,
      showConfirmPassword: false,
      privacyPolicyOpen: false,
      termsOpen: false,
      errors: {},
      passwordStrength: 0
    };

    this.passwordRequirements = [
        { text: "At least one capital letter", regex: /[A-Z]/ },
        { text: "At least one lowercase letter", regex: /[a-z]/ },
        { text: "At least one number", regex: /\d/ },
        { text: "At least one special character", regex: /[!@#$%^&*(),.?":{}|<>]/ },
        { text: "Minimum character length is 8 characters", test: (value: string) => value.length >= 8 }
      ];
  

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
  }

  handleInputChange = (field: keyof S, value: string | boolean) => {
    this.setState((prevState) => {
      const newState = { ...prevState, [field]: value };
      if (field === 'password') {
        this.checkPasswordStrength(value as string);
      }
      this.validateField(field, value);
      return newState;
    });
  }

  togglePasswordVisibility = (field: 'showPassword' | 'showConfirmPassword') => {

       this.setState((prevState) => ({
        ...prevState,
        [field]: !prevState[field]
      }));
  }

  handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (this.validateForm()) {
      this.createAccount();
    }
  }

  getPasswordRequirementIconStyle = (requirement: PasswordRequirement, password: string) => {

    const webStyle = {
        checkIcon: {
          color: '#34D399',
          fontSize: '16px',
          marginRight: '8px',
        },
        uncheckIcon: {
          color: '#9E9E9E',
          fontSize: '16px',
          marginRight: '8px',
        },
      };
    const isMet = requirement.regex 
      ? requirement.regex.test(password)
      : requirement.test 
        ? requirement.test(password)
        : true;
    return isMet ? webStyle.checkIcon : webStyle.uncheckIcon;
  }

  validateField = (field: keyof S, value: string | boolean) => {
    let errors = { ...this.state.errors };
    
    switch (field) {
      case 'name':
        errors.name = value ? '' : 'Name is required';
        break;
      case 'dms':
        errors.dms = value ? '' : 'DMS Number is required';
        break;
      case 'phone':
        errors.phone = /^\d{10}$/.test(value as string) ? '' : 'Invalid phone number';
        break;
      case 'email':
        errors.email = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(value as string) ? '' : 'Invalid email address';
        break;
      case 'password':
        errors.password = (value as string).length >= 8 ? '' : 'Password must be at least 8 characters';
        break;
      case 'confirmPassword':
        errors.confirmPassword = value === this.state.password ? '' : 'Passwords do not match';
        break;
      case 'consent':
        errors.consent = value ? '' : 'You must accept the terms and conditions';
        break;
    }

    this.setState({ errors });
  }

  validateForm = () => {
    let isValid = true;
    let errors = { ...this.state.errors };
  
    (Object.keys(this.state) as Array<keyof S>).forEach((key) => {
      if (key !== 'errors' && key !== 'showPassword' && key !== 'showConfirmPassword' && 
          key !== 'privacyPolicyOpen' && key !== 'termsOpen' && key !== 'passwordStrength') {
        const value = this.state[key];
        if (typeof value === 'string' || typeof value === 'boolean') {
          this.validateField(key, value);
          if (errors[key]) isValid = false;
        }
      }
    });
  
    this.setState({ errors });
    return isValid;
  }

  checkPasswordStrength = (password: string) => {
    let strength = 0;
    if (password.length >= 8) strength++;
    if (password.match(/[a-z]+/)) strength++;
    if (password.match(/[A-Z]+/)) strength++;
    if (password.match(/\d+/)) strength++;
    if (password.match(/[$@#&!]+/)) strength++;

    this.setState({ passwordStrength: strength });
  }

  createAccount = () => {
    // Implement account creation logic
  }

  setPrivacyPolicyOpen = (open: boolean) => {
    this.setState({ privacyPolicyOpen: open });
  }

  setTermsOpen = (open: boolean) => {
    this.setState({ termsOpen: open });
  }
}